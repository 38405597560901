import React  from 'react';
import styled from '@emotion/styled';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import HeaderMedia       from '@interness/theme-default/src/components/HeaderMedia';
import HeaderImage       from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';


const J = styled.p`
  text-align: justify;
`;

function IndexPage(props) {
  return (
    <>
      <HeaderImage>
        <HeaderMedia id={'index-4'} />
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <Heading subtitle={'Am schönen Murmelbachtal'}>Physiotherapie Simone Hartmann</Heading>
        <LiveAnnouncements showHeading={false}/>
        <Spacer/>
        <section>
          <div>
            <p>Leben ist Bewegung!</p>
            <J>Sowohl Körper als auch Geist und Seele müssen in Bewegung bleiben, sonst sind sie nicht in Einklang.</J>
            <J>Geht es uns seelisch nicht gut, meldet sich irgendwann der Körper und geht es uns körperlich nicht gut,
              belastet das unseren Geist und unsere Seele.</J>
            <J>Alle Sätze wie : " Die Last auf unseren Schultern", mir sitzt die Angst / Stress im Nacken", Zähne
              zusammenbeissen und durch", mir kommt die Galle hoch", "das liegt mit schwer im Magen" und "das belastet
              mein
              Herz" machen das Ganzheitliche zwischen Körper, Geist und Seele verständlich
            </J>
            <Spacer/>
            <h3>Hier mein Leistungsprogramm:</h3>
            <ul>
              <li>Ganzheitliche Therapie mit osteopathischen Techniken</li>
              <li><b>Physiotherapeutische Leistungen:</b></li>
              <li>Massage</li>
              <li>Krankengymnastik</li>
              <li>Manuelle Therapie</li>
              <li>Lymphdrainage</li>
              <li>Fango</li>
              <li>Kiefergelenksbehandlung (CMD)</li>
              <li>Sportphysiotherapie</li>
              <li>Kinesiotaping</li>
              <li>Beckenbodenbehandlung</li>
              <li>Hausbesuche</li>
            </ul>
            <J>Ich bitte um Ihr Verständnis, dass Terminabsagen spätestens 24 Stunden
              vor Behandlungsbeginn erfolgen müssen! Unentschuldigt nicht wahrgenommene
              oder nicht rechtzeitig abgesagte Termine werden privat mit einer Ausfallgebühr
              in Rechnung gestellt.</J>
            <J>Am Haus finden Sie zwei ausgeschilderte Patienten-Parkplätze. Entlang der Chamissostrasse gilt das eingeschränkte Halteverbot, bitte parken Sie dort nicht.
              Weitere Parkplätze finden Sie am Murmelbachparkplatz.</J>
            <J>Herzliche Grüße
              Ihre Simone Hartmann</J>
          </div>
          <Spacer/>
        </section>
        <section style={{ textAlign: 'center' }}>
          <CallToAction/>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

